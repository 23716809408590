<template>
  <div class="app">
    <div class="fx-vc-hb pd-8-20">
      <span class="title">贵州讯辉生态农业</span>
      <div style="padding-right: 50px" class="menu">
        <span class="active-nav">首页</span>
        <span @click="$router.push('/about')">关于公司</span>
      </div>
    </div>
    <div class="swiperContent" style="position: relative;">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <img :src="item.img" class="swiper-item" />
        </div>
      </div>
      <div class="swiper-pagination-left" @click="swiperNext('left')"></div>
      <div class="swiper-pagination-right" @click="swiperNext('right')"></div>

    </div>
    <div class="why">
      <div>为什么选择我们？</div>
      <div style="color:rgb(153, 153, 153)">
        公司秉承"超越自我，追求品质"的企业精神以及"诚信为本"的经营理念，为用户提供专业优质服务
      </div>
    </div>
    <div class="fx-vc-hb we" >
      <div v-for="(item,index) in list2" :key="index">
        <div class="fx-vb-hc">
          <span :ref="'n'+index">{{item.value}}</span>
          <span>{{item.unit}}</span>
        </div>
        <div>{{item.title}}</div>
      </div>
    </div>

    <div class="about">
      <div>
        <div>
          关于公司
        </div>
        <div class="at">
          贵州讯辉生态农业有限责任公司成立于2023年6月，注册资金500万。公司位于贵州省贵阳市观山湖区北大资源梦想城6号地块，是一家主营农副产品大宗贸易和销售的供应链公司。1.公司规模：20-50人的中小型企业。2.经营内容：主销根茎类产品，目前主供土豆，主要销往多多买菜     平台，线下本土连锁惠民生鲜超市.3.公司经营状况：正常经营，设有行政部，财务部，运营部及外联部。流水平均每月达到100万以上，并且每月呈现递增的形势。4.经营理念：公司本着诚信经营，以礼待人的信念，为顾客提供品质优越的商品，坚持做好售前品控，售后回访，积极处理和对待顾客反馈的意见和建议，保证供货的时效性，经营号公司的口碑，留住老客户，发展新资源。5.公司未来发展方向：农副业在近几年的行业发展中占重要位置，是百姓生活，国家生存不可或缺的一部分，作为刚需产品，也是国家政府支持的产业链，公司不仅仅满足于现有的业务，在接下来的时间，公司计划继续开展多多平台开仓的工作，一个月开仓达到5个以上，并同时对接美团，快驴，京东等电商平台。待根基越发牢固以后，向跨境电商的方向继续发展，行业的走势和团队的努力都将让我们强大起来。
        </div>
      </div>
    </div>
    <div class="fx-vc-hb foot">
      <div style="font-size: 16px; color: rgba(255, 255, 255, 0.404)">地址：贵州省贵阳市观山湖区北大资源梦想城6号地块</div>
        <div> 
      
        <a href="https://beian.miit.gov.cn/"  style="color: rgba(255, 255, 255, 0.404); font-size: 16px;" target="_blank">黔ICP备2023013000号-1</a>
      
      </div>

      <!-- <div>Tel/联系电话：020-000000 000-000000</div>
      <div>Mail/邮箱：xxx@.com</div> -->
    </div>
  </div>
</template>
<script>
import anime from 'animejs'
export default {
  data () {
    return {
      list: [
        { img: require('@/assets/1.jpg') },
         { img: require("@/assets/2.jpeg") },
       { img: require("@/assets/3.jpeg") },
      ],
      list2: [
        {
          title: '客户好评',
          value: '98',
          unit: '%'
        },
        {
          title: '国家专利',
          value: '18',
          unit: '项'
        },
        {
          title: '代理分布城市',
          value: '172',
          unit: '+'
        },
        {
          title: '合作经销商',
          value: '500',
          unit: '+'
        },
        {
          title: '合作单位',
          value: '1385',
          unit: '+'
        },
        {
          title: '优质客户群',
          value: '1500',
          unit: '+'
        }
      ]
    }
  },
  mounted () {
    this.swiper = new this.$swiper('.swiperContent', {

      //   slidesPerView: 4,
      loop: true,
      speed: 1000,
      autoplay: {
        disableOnInteraction: false,
        delay: 1500
      }
    })

    this.list2.forEach((v, index) => {
      anime({
        targets: this.$refs['n' + index][0],
        innerHTML: [0, v.value],
        round: 1,
        easing: 'easeInOutExpo'
      })
    })
  },
  methods: {
    swiperNext (type) {
      switch (type) {
        case 'left':
          this.swiper.slidePrev()
          break
        case 'right':
          this.swiper.slideNext()
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss">
*{
  box-sizing: border-box;
}
.at{
  height: 80%;
  overflow: hidden;
  text-indent: 20px;
  // text-overflow: ellipsis;
  // white-space: pre-wrap;
}
@keyframes cx{
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}
.swiper-pagination{

  &-left,&-right{
    position: absolute;
    z-index: 9;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-image: url('https://1.ss.faisys.com/image/rimage/jz/vbg01.png?v=202201101420');

    background-color: rgba($color: #000000, $alpha: 0.2);
    cursor: pointer;
    &:hover{
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }
  &-left{
    left: 20px;
    background-position: 0 0;
  }
  &-right{
    right: 20px;
    background-position: -45px 0;
  }
}
.app{
    &::-webkit-scrollbar{
        display: none;
    }
}
.foot{
  background: rgb(32,32,32);
  color: #666;
  padding: 60px 140px;
  >div{
    flex: 1;
    text-align: center;
  }
}
.about{
  background-image: url('https://20356703.s61i.faiusr.com/2/AD0I37zaCRACGAAgl_Td6wUo4M6X_Acw_g44_AU.jpg.webp');
  width: 100%;
  height: 671px;
  background-size: 100% 100%;
  margin-top: 50px;
  position: relative;
  >div{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('https://20356703.s61i.faiusr.com/4/AD0I37zaCRAEGAAgy_Xd6wUoppnssgQwwAc4kgQ.png');
    width: 60%;
    height: 80%;
    padding: 50px 0;
    padding-left: 18%;
    padding-right: 8%;
    >div:nth-of-type(1){
      color: rgb(255, 255, 255);
      font-size: 24px;
      position: relative;
      display: inline-block;
      margin-bottom: 60px;
      &::after{
        position: absolute;
        bottom: -20px;
        left: 0;
        height: 2px;
        background: white;
        width: 60%;
        content: '';
      }
    }
    >div:nth-of-type(2){
      line-height: 36px;
      color: white;
    }
}
}
.we{
  padding: 10px 30px;
  margin-top: 40px;
  >div{
    color: rgba(153, 153, 153, 1);
    flex: 1;
    text-align: center;
    >div:nth-of-type(1){
      color:#333;
      margin-bottom: 5px;
      >span:nth-of-type(1){
        font-size: 34px;
        margin-right: 2px;
    color: rgba(30, 80, 174, 1);
    font-family: 微软雅黑;
    font-weight: normal;
    text-decoration: none;
    font-style: normal;
      }
    }
  }
}
.why {
  opacity: 0;
  animation: cx 3s cubic-bezier(.25,.8,.5,1);
  animation-fill-mode: forwards;
  > div {
    margin: 0 auto;
    position: relative;
    text-align: center;
    // display: inline-block;
  }
  > div:nth-of-type(1) {
    font-size: 24px;
    color: #333;
    margin-top: 50px;
    margin-bottom: 40px;
    &::after{
      content: '';
      width: 43px;
      height: 3px;
      background: rgb(30, 80, 174);
      transform: translate(-50%,0%);
      position: absolute;
      left: 50%;
      bottom: -20px;
    }
  }
}
.swiperContent {
  position: relative;
  width: 100%;
  height: 458px;
  background: #000000;
  // border-radius: 6px 6px 6px 6px;
  overflow: hidden;
  margin-top: 0px;
  .swiper-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ::v-deep {
    .swiper-pagination-bullet {
      background: white;
    }
  }
  // background: red;
}
.active-nav {
  color: rgba(30, 80, 174, 0.8);
  padding: 25px 20px;
  border-bottom: 2px solid rgb(30, 80, 174);
}
.menu{

  >span{
    display: inline-block;
    width: 80px;
    text-align: center;
    margin-right: 10px;
    padding: 25px 0;
    cursor: pointer;
    &:hover{
      color: rgba(30, 80, 174, 0.8);

      border-bottom: 2px solid rgb(30, 80, 174);
    }
  }
}
.title {
  font-size: 30px;
  color: rgb(30, 80, 174);
  padding-left: 100px;
}
.app,
body,
html {
  padding: 0;
  margin: 0;
  &::-webkit-scrollbar{
        display: none;
        // width: 5px;
    }

}
html{
  font-size: 14px;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
@for $i from 12 to 40 {
  .fs-#{$i} {
    font-size: #{$i}px;
  }
}
.fc-g {
  color: rgba(255, 255, 255, 0.7);
}

$dr: (
  l: left,
  r: right,
  t: top,
  b: bottom,
);
@each $key, $value in $dr {
  @for $i from 1 to 70 {
    .mg-#{$key}-#{$i} {
      margin-#{$value}: #{$i}px;
    }
    .pd-#{$key}-#{$i} {
      padding-#{$value}: #{$i}px;
    }
  }
}
@for $i1 from 1 to 6 {
  .grid-#{$i1} {
    grid-template-columns: repeat(#{$i1}, 1fr);
    grid-template-rows: repeat(#{$i1}, 1fr);
    display: grid;
    grid-gap: 10px;
  }
  @for $i2 from 1 to 6 {
    .grid-#{$i1}-#{$i2} {
      grid-template-rows: repeat(#{$i1}, 1fr);
      grid-template-columns: repeat(#{$i2}, 1fr);
      display: grid;
      grid-gap: 10px;
    }
  }
}

@for $i1 from 0 to 10 {
  .o-#{$i1} {
    opacity: #{$i1/10};
  }
}
@for $i1 from 70 to 81 {
  .wh-#{$i1} {
    width: #{$i1}px;
    height: #{$i1}px;
  }
}
.fw-550 {
  font-weight: 550;
}
@for $i1 from 0 to 50 {
  .wh-#{$i1} {
    width: #{$i1}px;
    height: #{$i1}px;
  }
  .wd-#{$i1} {
    width: #{$i1}px;
  }
  .ht-#{$i1} {
    height: #{$i1}px;
  }
  @for $i2 from 0 to 50 {
    .mg-#{$i1}-#{$i2} {
      margin: #{$i1}px #{$i2}px;
    }
    .mg-#{$i1} {
      margin: #{$i1}px;
    }
    .pd-#{$i1}-#{$i2} {
      padding: #{$i1}px #{$i2}px;
    }
    .pd-#{$i1} {
      padding: #{$i1}px;
    }
  }
}

$color: (
  B1ECFF: #b1ecff,
  white: white,
  E6C3: #15e6c3,
  D1F4FF: #d1f4ff,
);
$name: (
  fc: "color",
  bg: "background",
);
@each $namekey, $nameValue in $name {
  @each $colorKey, $colorValue in $color {
    .#{$namekey}-#{$colorKey} {
      #{$nameValue}: $colorValue;
    }
  }
}

// flex 聚合
$alignItems: (
  vs: flex-start,
  vc: center,
  ve: flex-end,
);
$justifyContentKey: (
  hs: flex-start,
  hc: center,
  he: flex-end,
  hb: space-between,
  ha: space-around,
);
@each $aikey, $aivalue in $alignItems {
  .fx-#{$aikey} {
    display: flex;
    align-items: $aivalue;
  }
  .fx-#{$aikey}-c {
    display: flex;
    align-items: $aivalue;
    flex-direction: column;
  }
  @each $jckey, $jcvalue in $justifyContentKey {
    .fx-#{$jckey} {
      display: flex;
      justify-content: $jcvalue;
    }
    .fx-#{$jckey}-c {
      display: flex;
      justify-content: $jcvalue;
      flex-direction: column;
    }
    .fx-#{$aikey}-#{$jckey} {
      display: flex;
      align-items: $aivalue;
      justify-content: $jcvalue;
    }
    .fx-#{$aikey}-#{$jckey}-c {
      display: flex;
      align-items: $aivalue;
      justify-content: $jcvalue;
      flex-direction: column;
    }
  }
}
.fx-1 {
  flex: 1;
  width: 100%;
}

.bd-1 {
  border: 1px solid rgba($color: #0096ff, $alpha: 0.7);
}
.bd-b-1 {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.s-progress {
  margin-right: 20px;
  .el-progress-bar__outer {
    height: 10px !important;
  }
  .el-progress-bar__outer {
    overflow: visible;
  }
  .el-progress-bar__innerText {
    color: #15e6c3 !important;
    position: relative;
    z-index: 10;
    font-size: 14px;
    top: 20px;
    transform: translateX(65%);
  }
  .el-progress-bar__inner::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    background: #15e6c3;
    border: 5px solid white;
    border-radius: 50%;
    top: 0;
    transform: translate(-50%, -30%);
  }
}
.pointer {
  cursor: pointer;
}
.bd-r {
  border-radius: 50%;
}
.bg-g {
  background: rgba(255, 255, 255, 0.12);
}

.ctl-btn {
  border: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
  &:hover {
    border-color: #15e6c3;
    background: rgba(21, 230, 195, 0.4);
  }
}
.ctl-btn.active {
  border-color: #15e6c3;
  background: rgba(21, 230, 195, 0.4);
}

.s-input-number {
  .el-input-number__increase,
  .el-input-number__decrease {
    border-color: #15e6c3;
    background: rgba(21, 230, 195, 0.4);
    color: #69ff69 !important;
  }
  input {
    background: transparent;
    color: white;
    border-color: #15e6c3;
    font-size: 18px;
  }
}
.ht-450 {
  height: 500px;
}
.ht-100 {
  height: 300px !important;
}
.h-fill {
  height: 100%;
}
.fill-h {
  height: 100%;
}
.fill {
  width: 100%;
  height: 100%;
}
.p-r {
  position: relative !important;
}
.wd-1140 {
  width: 1140px !important;
}
.fill-w {
  width: 100% !important;
}
.ht-600 {
  height: 600px;
}
.t-l {
  text-align: left;
}

.s-tag.el-tag--success {
  background: transparent !important;
  color: #00ff9c !important;
  border-color: #00ff9c !important;
  height: 28px;
  line-height: 26px;
}
.s-tag.el-tag--danger {
  background: transparent !important;
  color: #ff6161 !important;
  border-color: #ff6161 !important;
  height: 28px;
  line-height: 26px;
}

.s-hover {
  cursor: pointer;
  &:hover {
    border-color: #15e6c3 !important;
    background: rgba(2, 122, 102, 0.8) !important;
  }
}
.bd-1-t {
  border: 1px solid transparent;
}
.trans {
  transition: all 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.s-pagina2 {
  text-align: right;
  .el-pager {
    li {
      height: 38px !important;
      line-height: 38px !important;
      min-width: 38px !important;
      background: transparent;
      color: white;
    }
  }
  button,
  input {
    height: 38px !important;
    min-width: 38px !important;
    line-height: 38px !important;
    background: transparent;
    color: white;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #409eff;
  }
  .el-pagination__jump {
    height: 38px !important;
    color: white;
    font-size: 14px;
    width: 120px;
    .el-input {
      width: 80px;
    }
  }
}

.s-board {
  margin-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  height: 500px;
  .row-item {
    color: #d1f4ff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }
  .header {
    font-weight: 550;
    border-top: 1px solid rgba($color: #00b4ff, $alpha: 0.4);
    border-bottom: 1px solid rgba($color: #00b4ff, $alpha: 0.4);
    background: radial-gradient(
      circle,
      transparent 40%,
      rgba($color: #5ac7f1, $alpha: 0.2)
    );
  }
  .see {
    color: #00ffff;
    cursor: pointer;
  }
}

.s-datetime {
  .el-input,
  .el-date-editor.el-input__inner {
    width: 180px !important;
    height: 35px;
    top: 5px;
    background: transparent;
    border: 1px solid;
    border-image: radial-gradient(
        circle,
        rgba(205, 255, 255, 1),
        rgba(0, 255, 255, 1)
      )
      1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
    span {
      color: white;
      line-height: 26px;
    }
    input {
      background: transparent;
      color: white;
      font-size: 16px;
      // padding: 0 30px;

      height: 35px;
      border: none;
      line-height: 35px;
    }
    i {
      font-size: 16px;
      line-height: 26px;
    }
  }
}

.yckz {
  width: 440px;
  height: 100%;
  position: relative !important;
  background: transparent !important;
  border-image: linear-gradient(
      180deg,
      rgba(205, 255, 255, 0),
      rgba(0, 255, 255, 1)
    )
    2 2 !important;
  overflow: hidden;
  // border-radius: 8px 8px 8px 8px;
}
</style>
